<template>
  <div class="mt-5">
    <b-container>
      <div>
        <h1 class="font-weight-bolder text-colorBlue">Visa Assistance</h1>
        <p class="tetx-colorText font-weight-bold" style="font-size: 1.2rem">
          we understand the paramount importance of safety and well-being. Our
          services encompass a robust security infrastructure featuring highly
          trained personnel and state-of-the-art surveillance systems. We ensure
          a visible and reliable security presence to deter potential threats,
          providing you with peace of mind.
        </p>
      </div>
    </b-container>

    <b-container class="mt-5 mb-5">
      <div>
        <h2 class="font-weight-bolder text-colorBlue text-center">
          Why Choose Us
        </h2>
      </div>
      <div>
        <b-row class="mt-3 d-flex align-items-center justify-content-center">
          <b-col
            align-self="stretch"
            md=""
            :class="[hoveredCol1 ? 'bg-colorBlue my-border' : 'bg-colorGrey']"
            class="mr-0 mr-md-1 mb-1 mb-md-0 image-container-hover"
            @mouseover="hoveredCol1 = true"
            @mouseleave="hoveredCol1 = false"
            style="border-radius: 12px"
          >
            <div class="text-center pt-3">
              <img
                src="@/assets/images/icons/travel/Integrated Services.svg"
                width="120px"
                class="base-img"
              />
              <img
                src="@/assets/images/icons/travel/Integrated Services white.svg"
                width="120px"
                class="hover-img"
              />
            </div>
            <div class="px-1 pt-2 text-center">
              <h4 class="text-colorGreen font-weight-bolder">
                Integrated Services
              </h4>
            </div>
            <div
              class="px-1 pt-50 pb-1 text-center"
              :class="[hoveredCol1 ? 'text-light ' : 'text-dark']"
              @mouseover="hoveredCol1 = true"
              @mouseleave="hoveredCol1 = false"
            >
              <p class="font-weight-bold">
                Our comprehensive approach seamlessly combines visa and various
                assistance services.
              </p>
            </div>
          </b-col>
          <b-col
            align-self="stretch"
            md=""
            :class="[hoveredCol2 ? 'bg-colorBlue my-border' : 'bg-colorGrey']"
            class="mr-0 mr-md-1 mb-1 mb-md-0 image-container-hover"
            @mouseover="hoveredCol2 = true"
            @mouseleave="hoveredCol2 = false"
            style="border-radius: 12px"
          >
            <div class="text-center pt-3">
              <img
                src="@/assets/images/icons/travel/Professionalism.svg"
                width="120px"
                class="base-img"
              />
              <img
                src="@/assets/images/icons/travel/Professionalism white.svg"
                width="120px"
                class="hover-img"
              />
            </div>
            <div class="px-1 pt-2 text-center">
              <h4 class="text-colorGreen font-weight-bolder">
                Professionalism
              </h4>
            </div>
            <div
              class="px-1 pt-50 pb-1 text-center"
              :class="[hoveredCol2 ? 'text-light ' : 'text-dark']"
              @mouseover="hoveredCol2 = true"
              @mouseleave="hoveredCol2 = false"
            >
              <p class="font-weight-bold">
                Count on our trained personnel dedicated to delivering
                high-quality services.
              </p>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-container>
    <b-container>
      <h1 class="mt-2 mb-1 font-weight-bolder text-colorBlue">
        Visa Assistance Guide
      </h1>
      <h3 class="font-weight-bolder text-colorBlue">How to Apply</h3>
      <p class="font-weight-bold text-colorText">
        Starting your visa application can seem overwhelming, but by following
        these steps, you can navigate the process with ease:
      </p>
      <ul class="text-colorText font-weight-bold">
        <li>Determine Your Visa Type</li>
        <li>Complete the Online Application Form</li>
        <li>Pay the Visa Application Fee</li>
        <li>Schedule an Appointment at the Visa Center</li>
      </ul>
      <h3 class="font-weight-bolder text-colorBlue">How to upload Documents</h3>
      <p class="font-weight-bold text-colorText">
        Submitting the correct documents is crucial for your visa application.
        Follow these guidelines to upload your documents securely:
      </p>
      <ul class="text-colorText font-weight-bold">
        <li>Scan Documents Clearly</li>
        <li>Save Documents in PDF Format</li>
        <li>Check File Sizes</li>
        <li>Verify Before Submission</li>
      </ul>
      <h3 class="font-weight-bolder text-colorBlue">Photograph Guide</h3>
      <p class="font-weight-bold text-colorText">
        Your visa application photograph must meet specific standards to be
        accepted. Follow these guidelines to ensure your photograph is compliant
      </p>
      <ul class="text-colorText font-weight-bold">
        <li>Use a Plain White Background</li>
        <li>Face the Camera Directly</li>
        <li>Avoid Hats or Head Coverings</li>
        <li>Submit a Recent Photo</li>
      </ul>
      <h3 class="font-weight-bolder text-colorBlue">Application Guide</h3>
      <p class="font-weight-bold text-colorText">
        Navigating the visa application process can be complex, but these steps
        will help ensure your application is thorough and accurate
      </p>
      <ul class="text-colorText font-weight-bold">
        <li>Provide Accurate Information</li>
        <li>Review Your Application</li>
        <li>Keep Copies of Your Application</li>
        <li>Track Your Application</li>
      </ul>
      <h3 class="font-weight-bolder text-colorBlue">Important Terms</h3>
      <p class="font-weight-bold text-colorText">
        Understanding these key terms will help you navigate the visa
        application process more effectively:
      </p>
      <ul class="text-colorText font-weight-bold">
        <li>Visa Fee</li>
        <li>Appointment</li>
        <li>Biometrics</li>
        <li>Visa validity</li>
      </ul>
    </b-container>
    <!-- <b-container>
      <h1 class="mt-2 mb-3 font-weight-bolder text-colorBlue">
        Visa Assistance
        <span class="font-weight-bolder text-colorGreen">Packages</span>
      </h1>
      <b-row class="">
        <b-col
          md="6"
          lg="4"
          v-for="(visaAssistancePackage, index) in visaAssistancePackages"
          :key="index"
          class="d-flex flex-column justify-content-between pt-1"
          :class="{ 'bg-colorGrey rounded': isHovered === index }"
          @mouseover="isHovered = index"
          @mouseleave="isHovered = null"
          align-self="stretch"
        >
          <div class="d-flex justify-content-between px-2 align-items-center">
            <div>
              <h5 class="font-weight-bolder text-colorText text-center">
                {{ visaAssistancePackage.name }}
              </h5>
            </div>
            <div class="text-center">
              <p
                class="font-weight-bolder text-colorText m-0 p-0 text-center"
                style="font-size: 0.7rem"
              >
                starting from
              </p>
              <h2 class="font-weight-bolder text-colorGreen text-center">
                {{ visaAssistancePackage.amount }}$
              </h2>
            </div>
          </div>

          <div class="px-2">
            <p
              v-html="visaAssistancePackage.description"
              class="font-weight-bold text-colorText text-left"
            ></p>
          </div>
           <div class="text-center">
            <b-button
              block
              rounded
              :variant="isInCart(visaAssistancePackage) ? 'colorBlue' : 'colorGreen'"
              size="md"
              class="font-weight-bolder px-5 mb-sm-1"
              @click="handleCartAction(visaAssistancePackage)"
            >
              {{ isInCart(visaAssistancePackage) ? 'View Cart' : 'Add to Cart' }}
            </b-button>
          </div> 
        </b-col>
      </b-row>
    </b-container> -->
  </div>
</template>

<script>
import VueSelectPaginated from "@/components/ui/VueSelectPaginated.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import util from "@/util.js";
import { mapActions, mapState } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  mixins: [util],
  components: {
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    VueSelectPaginated,
  },
  data() {
    return {
      isHovered: null,
      hoveredCol1: false,
      hoveredCol2: false,
      hoveredCol3: false,
      visaAssistancePackages: [],
    };
  },
  computed: {
    ...mapState({
      cart: (state) => state.appData.cart,
    }),
  },
  async mounted() {
    try {
      // this.initializeCart();

      const res = await this.getVisaAssistancePublic({});
      if (res.status === 200) {
        this.visaAssistancePackages = res.data;
        console.log(this.visaAssistancePackages);
      }
    } catch (error) {
      this.displayError(error);
    }
  },
  methods: {
    ...mapActions({
      getVisaAssistancePublic: "appData/getVisaAssistancePublic",
      addToCart: "appData/addToCart",
      removeFromCart: "appData/removeFromCart",
      // initializeCart: "appData/initializeCart",
    }),
    addCart(item) {
      this.addToCart({
        id: `${item.id}_${this.cartItemType.VISA_ASSISTANCE}`,
        name: item.name,
        itemId: item.id,
        price: item.amount,
        description: item.description,
        type: this.cartItemType.VISA_ASSISTANCE,
        quantity: 1,
        numberOfDays: 1,
      });
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Item added to cart successfully",
            variant: "success",
            icon: "BellIcon",
          },
        },
        {
          position: "top-right",
        }
      );
    },
    handleCartAction(item) {
      if (this.isInCart(item)) {
        this.$router.push({ name: "Cart" }); // Navigate to cart page
      } else {
        this.addCart(item);
      }
    },
    isInCart(item) {
      const cartItemId = `${item.id}_${this.cartItemType.VISA_ASSISTANCE}`;
      return this.cart.some((cartItem) => cartItem.id === cartItemId);
    },
  },
};
</script>

<style scoped>
.my-border {
  border-bottom: 8px solid #06bd46;
}
.hover-img {
  display: none;
}
.image-container-hover:hover .hover-img {
  display: block;
  margin: 0 auto;
}

.image-container-hover:hover .base-img {
  display: none;
}
</style>
